import React from "react";

import { Label, FormGroup, Alert } from "reactstrap";

const FormField = ({ label, value, name }) => (
  <FormGroup className={`text-form-group form-group`}>
    <Label for={name}>{label}</Label>
    <Alert color="dark">{value}</Alert>
  </FormGroup>
);

export default FormField;
