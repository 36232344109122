import React from "react";

export default ({ Icon, children, color, image }) => {
  if (image) {
    return (
      <div className="icon-box-container box">
        <div
          className="icon-box-icon"
          style={{ backgroundImage: `url('${image}')` }}
        ></div>
        <div className="icon-box-content">{children}</div>
      </div>
    );
  }

  return (
    <div className="icon-box-container box">
      <div className="icon-box-icon">
        <Icon color={color} />
      </div>
      <div className="icon-box-content">{children}</div>
    </div>
  );
};
