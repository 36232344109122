import React from "react";

import StatusText from "../../components/StatusText";

//
//  TODO: When we have user avatars available
// import IconBox from '../../components/IconBox';
//
// export default ({ title, image, subTitle }) => {
//   return (
//     <IconBox image={image}>
//       <div className="center pb-4">
//         <StatusText title={title} subTitle={subTitle} />
//       </div>
//     </IconBox>
//   );
// };

export default ({ title, image, subTitle }) => {
  return (
    <div className="box center p-3 mb-4">
      <StatusText title={title} subTitle={subTitle} />
    </div>
  );
};
