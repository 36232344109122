import * as actions from "./constants";

export const initialState = {
  complete: false,
};

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RECEIVE_SETTINGS:
      return {
        ...state,
        globalSettings: [...action.data.settings],
        complete: true,
      };
    case actions.HYDRATE_SETTINGS_SUCCESS:
      return { ...state, complete: true };
    case actions.SETTINGS_FETCH_FAIL:
      return { ...state, failed: true };
    default:
      return state;
  }
}

export default settingsReducer;
