import { take, call, put, select, takeLatest } from "redux-saga/effects";
import * as constants from "./constants";
import * as AuthActions from "./actions";
import * as AuthApi from "../../api/auth";
import { toastActions } from "pickups-ui-library";

import { storeAuthToken } from "utils/helpers";

function* loginUser(action) {
  try {
    const response = yield call(AuthApi.loginUser, action.data);
    let user = response.data.data[0];
    yield put(AuthActions.loginUserSuccess(user));
  } catch (e) {
    toastActions.showToast({
      message: e.message,
      type: "error",
      // noClose: true,
    });
    yield put({ type: constants.LOGIN_USER_FAILED, message: e.message });
  }
}

function* loginUserSuccess(action) {
  // No longer needed with cookies
  AuthApi.storeAuthToken(action.data.authToken);
}

function* logoutUser(action) {
  try {
    AuthApi.logout();
    yield put({ type: constants.LOGOUT_USER_SUCCESS });
  } catch (e) {
    yield put({ type: constants.LOGOUT_USER_FAILED, message: e.message });
  }
}

function* checkAuthToken(action) {
  try {
    const response = yield call(AuthApi.authToken, action.data);
    yield put({
      type: constants.CHECK_TOKEN_SUCCESS,
      data: response.data.data[0],
    });
  } catch (e) {
    yield put({ type: constants.CHECK_TOKEN_FAILED, message: e.message });
  }
}

// Individual exports for testing
export default function* authSaga() {
  yield takeLatest(constants.LOGIN_USER, loginUser);
  yield takeLatest(constants.LOGIN_USER_SUCCESS, loginUserSuccess);
  yield takeLatest(constants.LOGOUT_USER, logoutUser);
  yield takeLatest(constants.CHECK_TOKEN, checkAuthToken);
}
