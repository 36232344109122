import React from "react";
// import { ContainerQuery } from 'react-container-query';
// import classnames from 'classnames';
import { Button } from "reactstrap";

export default (props) => <Button {...props} />;

// export default Button;
//
// export default props => (
//   <ContainerQuery query={query}>
//     {params => (
//       <div className={classnames(params)}>
//         <div className={classnames('button', props.className)}>
//           {props.children}
//         </div>
//       </div>
//     )}
//   </ContainerQuery>
// );
