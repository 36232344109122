import React from "react";
import { Spinner } from "reactstrap";

export default () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      height: "100vh",
    }}
  >
    <div style={{ flex: 1 }}>
      <Spinner color="secondary" />
      <div style={{ fontSize: "1.25em", paddingTop: ".25em" }}>Loading...</div>
    </div>
  </div>
);
