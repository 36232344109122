/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import languageProviderReducer from "containers/LanguageProvider/reducer";
import { reducer as formReducer } from "redux-form";
import AuthReducer from "containers/Auth/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (injectedReducers = {}, history) => {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    form: formReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  // const mergeWithRouterState = connectRouter(history);
  // return mergeWithRouterState(rootReducer);
  return rootReducer;
};

export default createReducer;
