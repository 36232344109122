import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import { Label, FormGroup, FormFeedback, Input, Alert } from "reactstrap";
import { phoneNormalize } from "utils/helpers";

const PhoneInputComponent = React.forwardRef((props, ref) => (
  <Input {...props} innerRef={ref} />
));

const FormField = ({
  input,
  label,
  type,
  maxLength,
  placeholder,
  removeField,
  meta,
  feedback,
  name,
  disabled,
  meta: { touched, error, valid },
  required,
}) => (
  <FormGroup className={`${type}-form-group form-group`}>
    <Label for={name}>
      {label}
      {required && <span className="required-asterick">*</span>}
    </Label>
    {disabled ? (
      <Alert color="dark">{input.value}</Alert>
    ) : (
      <>
        {type !== "phone" ? (
          <Input
            {...input}
            invalid={!valid && touched}
            placeholder={placeholder}
            type={type}
            name={name}
            maxLength={maxLength}
            className={"box"}
          />
        ) : (
          <PhoneInput
            {...input}
            invalid={!valid && touched}
            onChange={(value) => {
              input.onChange(phoneNormalize(value));
            }}
            onBlur={(e) => {
              input.onBlur(phoneNormalize(e.target.value));
            }}
            country="US"
            value={"+1" + input.value}
            inputComponent={PhoneInputComponent}
          />
        )}
        {(touched && !valid) || error ? (
          <FormFeedback>{error}</FormFeedback>
        ) : null}
        {touched && valid ? <FormFeedback>{feedback}</FormFeedback> : null}
        <FormFeedback>{feedback}</FormFeedback>
      </>
    )}
  </FormGroup>
);

// FormField.propTypes = {
//   label: PropTypes.string,
//   type: PropTypes.string,
//   touched: PropTypes.bool,
//   error: PropTypes.string,
//   placeholder: PropTypes.string,
// }

export default FormField;
