import React from "react";
import classNames from "classnames";
import moment from "moment";
import * as Icons from "../../styles/icons";

// const StatusIcon = status => {
//   switch (status) {
//     case 'Completed':
//       return Icons.Complete;
//     case 'Pending':
//       return Icons.Scheduled;
//     case 'Routed':
//       return Icons.OnRoute;
//     case 'OutForPickup':
//       return Icons.OutForPickup;
//     default:
//       return Icons.Complete;
//   }
// };

const DisplayStopTime = (stop, timeWindows) => {
  let timeRange;
  const foundTimeWindow =
    timeWindows &&
    timeWindows.find((timeWindow) => {
      const normalizedTimeWindowStop = Number(timeWindow.last);
      const normalizedPickupStop = Number(stop);
      return normalizedPickupStop <= normalizedTimeWindowStop;
    });
  if (foundTimeWindow) {
    const { start, end } = foundTimeWindow;
    const formmattedStartTime =
      start && moment(foundTimeWindow.start).format("h:mm A");
    const formmattedEndTime =
      end && moment(foundTimeWindow.end).format("h:mm A");
    timeRange = `${formmattedStartTime || "n/a"} - ${
      formmattedEndTime || "n/a"
    }`;
  } else {
    if (stop <= 10) {
      timeRange = "9am - 12pm";
    }
    if (stop >= 11 && stop <= 17) {
      timeRange = "11am - 3pm";
    }
    if (stop > 17) {
      timeRange = "1pm - 4pm";
    }
  }
  return timeRange;
};

const StatusStep = ({ complete = 0, title, subTitle, Icon }) => {
  let stepClasses = {
    "pickup-status-step": true,
    complete: complete,
    pending: !complete,
  };

  return (
    <div className={classNames(stepClasses)}>
      <div className={"status-step-icon"}>
        <Icon />
        {complete ? (
          <div className="status-step-sub-icon">
            <Icons.Check />
          </div>
        ) : (
          <div className="status-step-sub-icon">
            <Icons.Waiting />
          </div>
        )}
      </div>
      <div className="status-step-text">
        <div className="status-step-title">{title}</div>
        <div className="status-step-subtitle">{subTitle}</div>
      </div>
    </div>
  );
};

const WhereScheduled = ({ pickup }) => {
  const { pickupSource } = pickup;
  // const { fullAddress, unit } = pickup.location;
  let title;

  if (pickupSource && pickupSource.label === "Website") {
    title = `Scheduled online`;
  } else {
    title = `Scheduled over the phone`;
  }

  // let address = fullAddress;

  // if (unit) {
  //   address = fullAddress + ' - ' + unit;
  // }

  return (
    <StatusStep
      complete={1}
      title={title}
      subTitle={"Your donation pickup is confirmed"}
      Icon={Icons.Scheduled}
    />
  );
};

const RouteStatus = ({ pickup }) => {
  let title;
  let complete;
  let subTitle;

  if (pickup.pickupStatus.label !== "Pending") {
    return null;
  }

  if (pickup.routeId) {
    title = `Assigned to a Route`;
    subTitle = `Your pickup has been assigned to a route`;
    complete = 1;
  } else {
    title = `Waiting for a route`;
    subTitle = `Your pickup will be assigned to a route the day before your scheduled donation`;
  }

  return (
    <StatusStep
      complete={complete}
      title={title}
      subTitle={subTitle}
      Icon={Icons.OnRoute}
    />
  );
};

const OutForPickup = ({ pickup, timeWindows }) => {
  if (pickup.routeId && pickup.pickupStatus.label === "Pending") {
    let title = `Expected arrival ${
        pickup.estimatedArrivalTime
          ? pickup.estimatedArrivalTime
          : DisplayStopTime(pickup.stopNumber, timeWindows)
      }`,
      subTitle = `Please leave your items outside`;
    // let complete = 0;
    return (
      <StatusStep
        complete={false}
        title={title}
        subTitle={subTitle}
        Icon={Icons.OutForPickup}
      />
    );
  } else {
    return null;
  }
};

const PickupComplete = ({ pickup }) => {
  let title = `Pickup Complete`,
    subTitle = `Thank you!`;

  if (pickup.pickupStatus.label === "Complete") {
    return (
      <StatusStep
        complete={true}
        title={title}
        subTitle={subTitle}
        Icon={Icons.Complete}
      />
    );
  }

  return null;
};

const PickupCancelled = ({ pickup }) => {
  let title = `Pickup Cancelled`,
    subTitle = `Your Donation pickup was cancelled`;
  let complete = 1;

  if (pickup.pickupStatus.label === "Canceled") {
    return (
      <StatusStep
        complete={complete}
        title={title}
        subTitle={subTitle}
        Icon={Icons.Cancelled}
      />
    );
  }
  return null;
};

export default ({ pickup, timeWindows }) => {
  // const {pickupStatus} = pickup
  // const {fullAddress, unit, routeId} = pickup.location

  return (
    <div className={"pickup-status-timeline"}>
      <WhereScheduled pickup={pickup} />
      <RouteStatus pickup={pickup} />
      <OutForPickup pickup={pickup} timeWindows={timeWindows} />
      <PickupCancelled pickup={pickup} />
      <PickupComplete pickup={pickup} />
    </div>
  );
};
