import request from "./request";
import Cookies from "js-cookie";
const AUTH_TOKEN_KEY = "pu.auth";

export const loginUser = (values) =>
  request({
    url: `/v2/public/users/authenticate`,
    method: "post",
    data: values,
  });

export const loginUserCode = (values) =>
  request({
    url: `/v2/public/users/authenticate_code`,
    method: "post",
    data: values,
  });

export const authToken = (token) =>
  request({ url: `/v2/users/verify_token`, method: "post", data: token });

export const forgotPassword = (values) =>
  request({
    url: `/v2/public/users/forgot_password`,
    method: "post",
    data: values,
  });

export const storeAuthToken = (token) => {
  Cookies.set(AUTH_TOKEN_KEY, token);
};

export const getAuthToken = () => {
  return Cookies.get(AUTH_TOKEN_KEY);
};

export const logout = (userLoggingOut = false) => {
  Cookies.remove(AUTH_TOKEN_KEY);

  if (process.env.NODE_ENV === "production") {
    return Cookies.remove(AUTH_TOKEN_KEY, {
      domain: process.env.REACT_APP_COOKIE_URL,
    });
  } else {
    return Cookies.remove(AUTH_TOKEN_KEY);
  }
};

export const loggedIn = () => {
  return !!Cookies.get(AUTH_TOKEN_KEY);
};
