import React from "react";
import * as Icons from "../../styles/icons";
import IconItem from "../../components/IconItem";
import StatusText from "../../components/StatusText";
import Button from "../../components/Button";
import IconBox from "../../components/IconBox";

export default ({
  date,
  status,
  statusText,
  type,
  shortcuts,
  leftButtonLabel,
  leftButtonAction,
  rightButtonLabel,
  rightButtonAction,
  layout = "left",
  onClick,
}) => {
  let updatedStatus = status;

  if (status === "Pending" && statusText === "Assigned to a Route") {
    updatedStatus = "OnRoute";
  }

  const StatusIcon = () => {
    switch (updatedStatus) {
      case "Completed":
        return Icons.Complete;
      case "Canceled":
        return Icons.Cancelled;
      case "Pending":
        return Icons.Scheduled;
      case "OnRoute":
        return Icons.OnRoute;
      case "OutForPickup":
        return Icons.OutForPickup;
      case "TaxReceipt":
        return Icons.TaxReceipt;
      case "Location":
        return Icons.OnRoute;
      default:
        return Icons.Complete;
    }
  };

  return (
    <div>
      {layout === "top" ? (
        <IconBox Icon={StatusIcon()} color={"#F7AECB"}>
          <div className="center pb-3">
            <StatusText title={date} subTitle={statusText} preTitle={type} />
            {shortcuts ? (
              <div className="d-flex flex-row pt-3 pr-3 pl-3">
                <div className="p-1 flex-fill">
                  <Button
                    onClick={() => {
                      leftButtonAction();
                    }}
                  >
                    {leftButtonLabel}
                  </Button>
                </div>
                <div className="p-1 flex-fill">
                  <Button
                    onClick={() => {
                      rightButtonAction();
                    }}
                  >
                    {rightButtonLabel}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </IconBox>
      ) : null}

      {layout === "left" ? (
        <div onClick={onClick}>
          <IconItem Icon={StatusIcon()} color={"#F7AECB"}>
            <StatusText title={date} subTitle={statusText} preTitle={type} />
          </IconItem>
          {shortcuts ? (
            <div className="d-flex flex-row pb-3 pr-3 pl-3">
              <div className="p-1 flex-fill">
                <Button
                  onClick={() => {
                    leftButtonAction();
                  }}
                >
                  {leftButtonLabel}
                </Button>
              </div>
              <div className="p-1 flex-fill">
                <Button
                  onClick={() => {
                    rightButtonAction();
                  }}
                >
                  {rightButtonLabel}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
