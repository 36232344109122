/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from "react";
import { Switch, Route } from "react-router-dom";

import GlobalStyle from "../../global-styles";
import "pickups-ui-library/styles/global.scss";
import Auth from "containers/Auth";
import GlobalSettings from "containers/GlobalSettings";
import { Toast } from "pickups-ui-library";

export default function App() {
  return (
    <div>
      <Toast />
      <GlobalSettings>
        <>
          <Switch>
            <Route path="/" component={Auth} />
            <Route path="/donation-pickup" component={Auth} />
          </Switch>
        </>
        <GlobalStyle />
      </GlobalSettings>
    </div>
  );
}
