import React from "react";
import { Alert } from "reactstrap";

class TimeoutAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: false });
    }, 3000);
  }

  render() {
    return <Alert isOpen={this.state.visible}>{this.props.children}</Alert>;
  }
}

export default TimeoutAlert;
