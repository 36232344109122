/*
 *
 * Auth actions
 *
 */

import * as actions from "./constants";
import { logout } from "api/auth";

export function loginUser(values) {
  return {
    type: actions.LOGIN_USER,
    data: values,
  };
}

export function loginUserSuccess(response) {
  return {
    type: actions.LOGIN_USER_SUCCESS,
    data: response,
  };
}

export function logoutUser() {
  logout();
  return {
    type: actions.LOGOUT_USER,
  };
}

export function checkToken(token) {
  return {
    type: actions.CHECK_TOKEN,
    data: token,
  };
}
