import React from "react";

export default ({ Icon, children, color, className }) => (
  <div className={`icon-item-container ${className}`}>
    <div className="icon-item-icon">
      <Icon color={color} />
    </div>
    <div className="icon-item-content">{children}</div>
  </div>
);
