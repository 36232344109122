/*
 *
 * Auth reducer
 *
 */

import * as actions from "./constants";

export const initialState = {
  token: false,
  pending: false,
  logged: false,
  id: undefined,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHECK_TOKEN:
      return { ...state, pending: true, token: action.data };

    case actions.CHECK_TOKEN_SUCCESS:
      return { ...state, pending: false, logged: true, user: action.data };

    case actions.CHECK_TOKEN_FAILED:
      return { ...state, pending: false, logged: false, token: undefined };

    case actions.LOGIN_USER_SUCCESS:
      return { ...state, pending: false, logged: true, user: action.data };

    case actions.LOGOUT_USER:
      return { initialState };

    default:
      return state;
  }
}

export default authReducer;
