import { toast } from "react-toastify";

const toastActions = {
  showToast: ({ message, timeToClose, position, type, noClose }) => {
    // position can be one of the following:
    // TOP_RIGHT, TOP-CENTER, TOP-LEFT, BOTTOM-RIGHT, BOTTOM-CENTER, BOTTOM-LEFT
    const toastConfig = {
      autoClose: noClose ? false : timeToClose || 4000,
      position: position ? toast.POSITION[position] : toast.POSITION.TOP_RIGHT,
      // hideProgressBar: true,
      className: `toast-notification ${type && `toast-notification--${type}`}`,
    };
    toast[type || "success"](message, toastConfig);
  },
};

export default toastActions;
