import { connect } from "react-redux";
import { compose } from "redux";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import saga from "./saga";
import reducer from "./reducer";
import { fetchSettings } from "./actions";
import { toastActions } from "pickups-ui-library";

const GlobalSettings = (props) => {
  const { fetchSettings, globalSettings, fetchFailed } = props;
  if (!globalSettings) {
    fetchSettings();
  }
  if (fetchFailed) {
    toastActions.showToast({
      message: "Unable to fetch app settings",
      type: "error",
      noClose: true,
    });
    return null;
  } else {
    return props.children;
  }
};

const mapStateToProps = (state) => ({
  globalSettings: state.settings.globalSettings,
  fetchFailed: state.settings.failed,
  isComplete: state.settings.isComplete,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetchSettings: () => dispatch(fetchSettings()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "settings", reducer });
const withSaga = injectSaga({ key: "settings", saga });

export default compose(withReducer, withSaga, withConnect)(GlobalSettings);
