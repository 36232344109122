import React from "react";
import Loader from "react-loader-spinner";

export default (props) => {
  return (
    <Loader
      type="Oval"
      color={props.color || "#333"}
      height={props.height || 24}
      width={props.width || 24}
      {...props}
    />
  );
};
