import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

export default ({ children }) => (
  <ListGroup className={"box"}>
    {children
      .filter((x) => x)
      .map((Item) => (
        <ListGroupItem>{Item}</ListGroupItem>
      ))}
  </ListGroup>
);
