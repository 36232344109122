import React from "react";

export default (props) => {
  const { children, title, count, ActionComponent } = props;
  return (
    <div className="section-component">
      {title ? (
        <div className="section-header">
          <div className="section-component-title">{title}</div>
          {count ? (
            <div className="section-component-count">{count}</div>
          ) : null}
          {ActionComponent ? (
            <div>
              <ActionComponent />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="section-component-body">{children}</div>
    </div>
  );
};
