/*
 *
 * Auth constants
 *
 */

export const DEFAULT_ACTION = "app/Auth/DEFAULT_ACTION";

export const LOGIN_USER = "app/Auth/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "app/Auth/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "app/Auth/LOGIN_USER_SUCCESS";

export const LOGOUT_USER = "app/Auth/LOGOUT_USER";
export const LOGOUT_USER_FAILED = "app/Auth/LOGOUT_USER_FAILED";
export const LOGOUT_USER_SUCCESS = "app/Auth/LOGOUT_USER_SUCCESS";

export const CHECK_TOKEN = "app/Auth/CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "app/Auth/CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAILED = "app/Auth/CHECK_TOKEN_FAILED";

export const HYDRATE_USER = "app/Auth/HYDRATE_USER";
export const HYDRATE_SUCCESS = "app/Auth/HYDRATE_SUCCESS";
