import axios from "axios";
import { logout } from "./auth";

//TODO: Abstract this out
// let apiHostname = `${window.location.origin}`
let apiHostname = `${process.env.REACT_APP_API_URL}`;

// if (process.env.NODE_ENV === 'development') {
//   apiHostname = 'http://localhost:3002/api'
// }

const baseUrl = `${apiHostname}`;
const transformResponse = [
  (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      if (data === "Unauthorized") {
        logout();
      } else if (data === "OK") {
        // This is to deal with express returning plaintext by default for DELETE routes
        return {};
      } else {
        console.error(e);
      }
    }
  },
];

export default (...args) => {
  let request = axios.create({
    baseURL: baseUrl, // TODO: Make configurable by env variable/config
    withCredentials: true,
    transformResponse: transformResponse,
    timeout: 10000,
  });

  return request(...args);
};
