/**
 *
 * Auth
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";
import makeSelectAuth from "./selectors";
import reducer from "./reducer";
import saga from "./saga";
import messages from "./messages";
import { Route, Switch, Redirect } from "react-router-dom";
import Public from "containers/Public/Loadable";
// import AuthorizedRoute from '../App';

import * as auth from "./actions";
import { storeAuthToken, getAuthToken, logout, loggedIn } from "api/auth";
import FullPageLoader from "components/FullPageLoader";
import Dashboard from "containers/Dashboard/Loadable";

import "./styles.scss";

class Auth extends React.Component {
  constructor(props) {
    super(props);

    this.handleAuth();
  }

  handleAuth() {
    this.props.checkToken();
  }

  render() {
    return (
      <Switch>
        {this.props.pending ? (
          <FullPageLoader />
        ) : this.props.logged ? (
          <Route path="/" component={Dashboard} />
        ) : (
          <Route path="/" component={Public} />
        )}
      </Switch>
    );
  }
}

Auth.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  pending: store.auth.pending,
  logged: store.auth.logged,
  token: store.auth.token,
  id: store.auth.id,
  // fetchingUser: store.user.pending,
  // pending: false,
  // logged: false
});

function mapDispatchToProps(dispatch) {
  let token = getAuthToken();

  // storeAuthToken('JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NDI0NzIzLCJpYXQiOjE1NTEyOTQ5MjgsImV4cCI6MTU1MTU1NDEyOH0.DcipJCZ8nzInmy1SAXk3Wa5lHM1qourDYLviHGkRH3s')
  return {
    dispatch,
    checkToken: () => (token ? dispatch(auth.checkToken(token)) : null),
    // noToken: (dispatch)=>{auth.checkToken(getAuthToken())}
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "auth", reducer });
const withSaga = injectSaga({ key: "auth", saga });

export default compose(withReducer, withSaga, withConnect)(Auth);
