import React from "react";
import * as Icons from "../../styles/icons";
import classNames from "classnames";

export default ({ label, Icon, onClick, layout = "left" }) => {
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className={classNames("square-button", layout, "box")}
    >
      <Icon size={22} />
      {label}
    </div>
  );
};
