import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import PaginationComponent from "../Pagination";

//
export default class PaginatedList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
    };
  }

  handleSelectPage = (page) => {
    this.setState({ activePage: page });
  };

  render() {
    const { children, perPage = 5 } = this.props;

    let pages = [];

    children.map((child, index) => {
      let page = Math.floor(index / perPage) + 1;

      if (!pages[page]) {
        pages[page] = [];
      }
      pages[page].push(<ListGroupItem key={index}>{child}</ListGroupItem>);
      return true;
    });

    return (
      <>
        <ListGroup className={"box"}>
          {pages[this.state.activePage].map((page) => page)}
        </ListGroup>
        {pages.length > 2 ? (
          <PaginationComponent
            activePage={this.state.activePage}
            totalItems={children.length}
            pageSize={perPage}
            onSelect={this.handleSelectPage}
          />
        ) : null}
      </>
    );
  }
}

// export default ({children}) => <ListGroup className={'box'}>
// {children.filter(x => x).map(Item => <ListGroupItem>{Item}</ListGroupItem>)}
// </ListGroup>
